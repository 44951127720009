@tailwind base;
@tailwind components;
@tailwind utilities;

hide-scroll::-webkit-scrollbar {
  display: none;
}

.animate-tada {
  animation: myanimation 500ms;
}

.hide-scroller::-webkit-scrollbar {
  display: none;
}

.hide-scroller-child div::-webkit-scrollbar {
  display: none;
}

.hide-ampm [data-type='dayPeriod'] {
  display: none;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.backdrop_col {
  background-color: hsl(var(--nextui-overlay) / 0.5);
}

@keyframes myanimation {
  0% {
    opacity: 0;
    scale: 0.3 0.3;
  }

  50% {
    opacity: 1;
    scale: 1.05 1.05;
  }

  100% {
    scale: 1 1;
  }
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
